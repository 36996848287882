import "./Spinner.css";
import React from "react";

function Spinner({className = null}) {
    return (
        <div className={`spinner ${className ? className : ""}`}>
            <div className="spinner__line spinner__line--1" />
            <div className="spinner__line spinner__line--2" />
            <div className="spinner__line spinner__line--3" />
            <div className="spinner__line spinner__line--4" />
            <div className="spinner__line spinner__line--5" />
            <div className="spinner__line spinner__line--6" />
            <div className="spinner__line spinner__line--7" />
            <div className="spinner__line spinner__line--8" />
            <div className="spinner__line spinner__line--9" />
            <div className="spinner__line spinner__line--10" />
            <div className="spinner__line spinner__line--11" />
            <div className="spinner__line spinner__line--12" />
        </div>
    );
}

export default Spinner;
