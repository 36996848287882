var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _NewsletterServiceSDK_endpointUrl, _NewsletterServiceSDK_newsletterAdapter;
class NewsletterServiceSDK {
    constructor(endpointUrl, newsletterAdapter) {
        _NewsletterServiceSDK_endpointUrl.set(this, void 0);
        _NewsletterServiceSDK_newsletterAdapter.set(this, void 0);
        this.getAllNewsletter = () => __awaiter(this, void 0, void 0, function* () {
            return fetch(__classPrivateFieldGet(this, _NewsletterServiceSDK_endpointUrl, "f") + "/all")
                .then(response => response.json())
                .then(response => response.data.items);
        });
        this.getAllNewsletterWithUserSubscriptions = (userId, fbTokenId) => __awaiter(this, void 0, void 0, function* () {
            return fetch(__classPrivateFieldGet(this, _NewsletterServiceSDK_endpointUrl, "f") + "/users/" + userId + "/subscriptions", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + fbTokenId
                }
            })
                .then(response => response.json())
                .then(response => response.data.items);
        });
        __classPrivateFieldSet(this, _NewsletterServiceSDK_endpointUrl, endpointUrl, "f");
        __classPrivateFieldSet(this, _NewsletterServiceSDK_newsletterAdapter, newsletterAdapter, "f");
    }
    subscribeAllNewslettersWithEmail(newsletters, email) {
        return __classPrivateFieldGet(this, _NewsletterServiceSDK_newsletterAdapter, "f").identifyFromEmail(email)
            .then(() => {
            newsletters.forEach(newsletter => {
                __classPrivateFieldGet(this, _NewsletterServiceSDK_newsletterAdapter, "f").subscribeToGroupWithEmail(newsletter, email);
            });
            window.dispatchEvent(new CustomEvent("all.newsletters.subscriptionCompleted", {
                "detail": {
                    "newsletters": newsletters.map(newsletter => newsletter.id),
                },
            }));
        });
    }
    subscribeWithEmail(newsletter, email) {
        __classPrivateFieldGet(this, _NewsletterServiceSDK_newsletterAdapter, "f").subscribeToGroupWithEmail(newsletter, email);
    }
    unsubscribeWithEmail(newsletter, email, readerId) {
        return fetch(__classPrivateFieldGet(this, _NewsletterServiceSDK_endpointUrl, "f") + "/unsubscribe?email=" + encodeURIComponent(email) + "&newsletter=" + encodeURIComponent(newsletter.id) + "&campaign=hub&readerId=" + encodeURIComponent(readerId), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(() => {
            window.dispatchEvent(new CustomEvent("newsletter.unsubscriptionCompleted", {
                "detail": {
                    "userEmail": email,
                    "newsletterId": newsletter.id,
                },
                "bubbles": true,
                "composed": true,
            }));
        });
    }
    customizeNewsletter(newsletterSlug, readerId, sections) {
        return fetch(__classPrivateFieldGet(this, _NewsletterServiceSDK_endpointUrl, "f") + "/users/" + readerId + "/subscriptions/" + newsletterSlug, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                sections
            }),
        }).then(response => {
            if (response.status !== 200) {
                return Promise.reject("Failed to customize newsletter");
            }
        });
    }
    getCustomizeNewsletter(newsletterSlug, readerId) {
        return fetch(__classPrivateFieldGet(this, _NewsletterServiceSDK_endpointUrl, "f") + "/users/" + readerId + "/subscriptions/" + newsletterSlug, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        }).then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.json();
        }).then(response => response.data.sections);
    }
}
_NewsletterServiceSDK_endpointUrl = new WeakMap(), _NewsletterServiceSDK_newsletterAdapter = new WeakMap();
export default NewsletterServiceSDK;
