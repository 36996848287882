var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import "./SubscriptionBox.css";
import "./SubscriptionBox--size-medium.css";
import "./SubscriptionBox--size-large.css";
import Spinner from "../Spinner";
import Form from "./Form/Form";
const getContainerSize = (rootElement) => {
    if (rootElement.offsetWidth >= 1290) {
        return "large";
    }
    if (rootElement.offsetWidth >= 1024) {
        return "medium";
    }
    return "small";
};
export default function SubscriptionBox({ newsletterService, lpauth, slug, rootElement }) {
    const [isLoggedIn, setIsLoggedIn] = useState(lpauth.isAuthReady() ? lpauth.isLoggedIn() : null);
    const [currentUserEmail, setCurrentUserEmail] = useState(lpauth.isAuthReady() ? (lpauth.currentUser() ? lpauth.currentUser().email : null) : null);
    const [isNewslettersLoading, setIsNewslettersLoading] = React.useState(true);
    const [containerSize, setContainerSize] = React.useState(undefined);
    const [newsletter, setNewsletter] = React.useState(null);
    const fetchAllNewsletters = () => __awaiter(this, void 0, void 0, function* () {
        return newsletterService.getAllNewsletter();
    });
    const fetchAllNewslettersWithUserSubscriptions = () => __awaiter(this, void 0, void 0, function* () {
        return lpauth.currentUser().getIdToken().then((token) => newsletterService
            .getAllNewsletterWithUserSubscriptions(lpauth.currentUser().uid, token));
    });
    useEffect(() => {
        const handleResize = () => {
            setContainerSize(getContainerSize(rootElement));
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        lpauth.onAuthStateChanged(function (event) {
            setIsLoggedIn(!!(event && event.user));
            setCurrentUserEmail(event && event.user && event.user.email);
        });
    }, []);
    useEffect(() => {
        setIsNewslettersLoading(true);
        (isLoggedIn ? fetchAllNewslettersWithUserSubscriptions() : fetchAllNewsletters())
            .then(availableNewsletters => setNewsletter(availableNewsletters.find((element) => element.id === slug)))
            .finally(() => setIsNewslettersLoading(false));
    }, [isLoggedIn]);
    const handleSubmit = (email) => {
        if (isLoggedIn) {
            newsletterService.subscribeWithEmail(newsletter, email);
            return Promise.resolve();
        }
        else {
            return newsletterService.subscribeAllNewslettersWithEmail([newsletter], email);
        }
    };
    const handleSuccess = () => {
        const newNewsletter = Object.assign({}, newsletter);
        newNewsletter.subscribed = true;
        setNewsletter(newNewsletter);
    };
    const isFormDisplayReady = () => {
        return isLoggedIn !== null && !isNewslettersLoading;
    };
    if (!newsletter) {
        return (React.createElement("div", { className: `newsletterSubscriptionBox newsletterSubscriptionBox--size-${containerSize}` },
            React.createElement("div", { className: "newsletterSubscriptionBox__spinnerWrapper" },
                React.createElement(Spinner, null))));
    }
    return (React.createElement("div", { style: { borderColor: newsletter.subscribed ? "white" : newsletter.borderColor }, className: `newsletterSubscriptionBox newsletterSubscriptionBox--size-${containerSize}`, "data-location": "subscriptionBox" },
        React.createElement("div", { className: "newsletterSubscriptionBox__inner" },
            React.createElement("div", { className: "newsletterSubscriptionBox__iconWrapper" },
                React.createElement("img", { className: "newsletterSubscriptionBox__icon", src: `data:image/svg+xml;base64,${btoa(containerSize === "small" ? newsletter.iconPortrait : newsletter.iconLandscape)}`, alt: newsletter.title })),
            React.createElement("div", { className: "newsletterSubscriptionBox__description" },
                React.createElement("span", null, newsletter.description),
                React.createElement("span", { className: "newsletterSubscriptionBox__frequency" }, newsletter.frequency)),
            isFormDisplayReady() ? (React.createElement(Form, { onSubmit: handleSubmit, onSuccess: handleSuccess, isLoggedIn: isLoggedIn, isSubscribed: newsletter.subscribed, currentUserEmail: currentUserEmail })) : (React.createElement("div", { className: "newsletterSubscriptionBox__formSpinner" },
                React.createElement(Spinner, null))))));
}
