import React from "react";
import "./Form.css";
import Spinner from "../../Spinner/Spinner";
function getErrorMessage(error) {
    switch (error) {
        case "errCourrielSyntaxe":
            return "Veuillez saisir un courriel valide.";
        case "errCourriel":
            return "L'adresse courriel est invalide.";
        default:
            return "Une erreur inconnue est survenue.";
    }
}
export default function Form({ isLoggedIn, isSubscribed, currentUserEmail, onSubmit, onSuccess }) {
    const [isNetworkCallInProgress, setIsNetworkCallInProgress] = React.useState(false);
    const [networkErrorMessage, setNetworkErrorMessage] = React.useState("");
    const [email, setEmail] = React.useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsNetworkCallInProgress(true);
        onSubmit(currentUserEmail || email)
            .then(() => onSuccess())
            .catch((error) => {
            console.warn("error caught %o", error);
            setNetworkErrorMessage(getErrorMessage(error.message));
        })
            .finally(() => setIsNetworkCallInProgress(false));
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value) || !event.target.value.length) {
            setNetworkErrorMessage("");
        }
        else {
            setNetworkErrorMessage(getErrorMessage("errCourrielSyntaxe"));
        }
    };
    const isSubmitButtonActive = () => {
        if (isNetworkCallInProgress) {
            return false;
        }
        return !networkErrorMessage.length && !!email.length;
    };
    const Footer = (React.createElement("div", { className: "newsletterSubscriptionBox__formFooter" },
        React.createElement("a", { className: "newsletterSubscriptionBox__formFooterLink", href: "/infolettres?utm_source=lpca&utm_medium=boite_inscription&utm_campaign=page_gestion_infolettre", target: "_self" }, "Toutes les infolettres"),
        React.createElement("a", { className: "newsletterSubscriptionBox__formFooterLink", href: "https://info.lapresse.ca/politique-de-confidentialite", target: "_self" }, "Voir notre politique de confidentialit\u00E9")));
    if (isSubscribed) {
        return (React.createElement("div", { className: "newsletterSubscriptionBox__form newsletterSubscriptionBox__form--subscribed" },
            React.createElement("div", { className: "newsletterSubscriptionBox__formSubscribedBody" },
                React.createElement("div", null,
                    React.createElement("svg", { width: "65px", height: "65px", viewBox: "0 0 65 65", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
                        React.createElement("title", null, "Succes"),
                        React.createElement("g", { id: "boites-d'inscription", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                            React.createElement("g", { id: "Aujourd'hui-l'info/inscrit/large", transform: "translate(-894.000000, -78.000000)", fill: "#000000", fillRule: "nonzero" },
                                React.createElement("g", { id: "icn_success", transform: "translate(894.000000, 78.000000)" },
                                    React.createElement("polygon", { id: "check", points: "16.2871429 29.64 13 32.9271429 28.0985714 48.0257143 51.74 24.3842857 48.4528571 21.1064286 28.0985714 41.4607143" }),
                                    React.createElement("path", { d: "M32.5,0 C50.4492544,0 65,14.5507456 65,32.5 C65,50.4492544 50.4492544,65 32.5,65 C14.5507456,65 0,50.4492544 0,32.5 C0,14.5507456 14.5507456,0 32.5,0 Z M32.5,60.3571429 C47.8850752,60.3571429 60.3571429,47.8850752 60.3571429,32.5 C60.3571429,17.1149248 47.8850752,4.64285714 32.5,4.64285714 C17.1149248,4.64285714 4.64285714,17.1149248 4.64285714,32.5 C4.64285714,47.8850752 17.1149248,60.3571429 32.5,60.3571429 Z", id: "contour" })))))),
                React.createElement("div", null,
                    React.createElement("div", null,
                        "Vous \u00EAtes inscrit avec",
                        React.createElement("span", { className: "newsletterSubscriptionBox__currentUserEmail" },
                            " ",
                            currentUserEmail || email,
                            " ")))),
            Footer));
    }
    if (isLoggedIn && !isSubscribed) {
        return (React.createElement("form", { onSubmit: handleSubmit, className: "newsletterSubscriptionBox__form newsletterSubscriptionBox__form--loggedIn" },
            React.createElement("div", { className: "newsletterSubscriptionBox__formDescription" },
                React.createElement("span", null,
                    "Inscrivez-vous avec votre compte",
                    React.createElement("br", null),
                    "La\u00A0Presse"),
                React.createElement("span", { className: "newsletterSubscriptionBox__currentUserEmail" },
                    " ",
                    currentUserEmail,
                    " "),
                !!networkErrorMessage.length && React.createElement("div", { className: "newsletterSubscriptionBox__inputFeedback" }, networkErrorMessage)),
            React.createElement("button", { disabled: !!isNetworkCallInProgress, className: "newsletterSubscriptionBox__button", type: "submit" }, !!isNetworkCallInProgress ? React.createElement(Spinner, { className: "newsletterSubscriptionBox__formButtonSpinner" }) : "Je m'inscris"),
            Footer));
    }
    return (React.createElement("form", { onSubmit: handleSubmit, className: "newsletterSubscriptionBox__form newsletterSubscriptionBox__form--loggedOut" },
        React.createElement("div", { className: "newsletterSubscriptionBox__inputWrapper" },
            React.createElement("input", { id: "newsletter-email-input", type: "email", name: "email", className: `newsletterSubscriptionBox__input ${!networkErrorMessage.length ? "" : "newsletterSubscriptionBox__input--error"}`, placeholder: "Courriel", onChange: handleEmailChange }),
            !!networkErrorMessage.length && React.createElement("div", { className: "newsletterSubscriptionBox__inputFeedback" }, networkErrorMessage)),
        React.createElement("button", { disabled: !isSubmitButtonActive(), className: "newsletterSubscriptionBox__button", type: "submit" }, !!isNetworkCallInProgress ? React.createElement(Spinner, { className: "newsletterSubscriptionBox__formButtonSpinner" }) : "Je m'inscris"),
        Footer));
}
